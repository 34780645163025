import { Button, Divider, TextField, View } from '@aws-amplify/ui-react'
import { useEffect, useState } from 'react'
import { Dropzone } from '../shared/UploadDropzone'
import { fetcher } from '../../util/fetcher'
import { updateFullKey } from './util/update-full-key'

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  setUploadPath: () => void;
  listAllDocuments: () => void;
  path?: any

}

const parseDocumentName = (input: string) => {
  const fileExtension = getDocumentType(input)
  const fileName = input.substring(0, input.lastIndexOf('.'))
  const parsedFileName = fileName.toLowerCase().replace(/\s+/g, '-')

  if (!fileExtension) {
    throw new Error('No file extension found')
  }

  return parsedFileName + fileExtension
}

const getDocumentType = (fileName: string) => {
  const extensionIndex = fileName.lastIndexOf('.')
  if (extensionIndex !== -1) {
    return fileName.substring(extensionIndex)
  }
  return ''
}

const isValidS3ObjectKey = (key: string): boolean => {
  const MIN_KEY_LENGTH = 1
  const MAX_KEY_LENGTH = 1024
  const INVALID_CHARACTERS_REGEX = /[<>{}%\\^]/

  const keyLength = key.length

  if (keyLength < MIN_KEY_LENGTH || keyLength > MAX_KEY_LENGTH) {
    return false
  }

  if (/\s/.test(key)) {
    return false
  }

  if (INVALID_CHARACTERS_REGEX.test(key)) {
    return false
  }

  return true
}

export const UploadDocumentModal = ({ path, isOpen, setUploadPath, onClose, listAllDocuments }: ModalProps) => {

  const [isUploading, setIsUploading] = useState('')
  const [objectPath, setObjectPath] = useState('/')

  const [document, setDocument] = useState()
  const [documentName, setDocumentName] = useState()
  const [documentType, setDocumentType] = useState()

  useEffect(() => {
    if (path && path.fullKey) {
      const { fullKey } = updateFullKey(path)
      setObjectPath(fullKey)
    }
  }, [path])

  const handleFileUpload = (uploadedFile) => {
    setDocument(uploadedFile)
  }

  const handleClose = () => {
    setDocument()
    setUploadPath('')
    setObjectPath('')
    setDocumentType()
    setDocumentName()
    onClose()
  }

  useEffect(() => {
    if (document && document.name) {
      try {
        setDocumentName(parseDocumentName(document.name))
        setDocumentType(getDocumentType(document.name))
      } catch (error) {
        alert(error)
        setDocument()
      }
    }
  }, [document])

  const handleSubmit = async () => {
    if (!document) {
      alert('Choose file first')
      return
    }

    setIsUploading(true)

    let key = ''
    if (objectPath[objectPath.length - 1] !== '/') {
      key = `${objectPath}/`
    } else {
      key = objectPath
    }

    key = `${key}${documentName}`

    if (key.startsWith('/')) {
      key = key.substring(1)
    }

    if (!isValidS3ObjectKey(key)) {
      alert('Invalid document path')
      return
    }

    try {
      await fetcher('/docs/new', {
        key: key,
        document: document.encoded,
      })
      setIsUploading(false)
      alert('Document uploaded')
      listAllDocuments()
    } catch (error) {
      console.error(error)
      setIsUploading(false)
      alert(`Unexpected error: ${error.message}`)
    }
  }

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(5px)',
      display: isOpen ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        minWidth: '80vw',
        maxHeight: '80%',
        overflow: 'auto',
      }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2>Upload Document</h2>
          <Button
            onClick={handleClose}
            style={{
              height: '3rem',
            }}
          >
            X
          </Button>
        </div>
        <Divider/>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <View
            as={'div'}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <View
              as={'div'}
              style={{
                margin: '2rem',
              }}
            >
              {/* TODO What doc types we need to support */}
              <Dropzone onFileUpload={handleFileUpload} accept=".pdf" multiple={false}/>
            </View>
          </View>
        </div>
        <View
          as={'div'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <TextField
            label="Path"
            descriptiveText="Define path you want to upload file to. If empty, document will uploaded to the root."
            value={objectPath}
            onChange={(event) => setObjectPath(event.target.value)}
            placeholder="Define document folder path"
          />
          {documentName && <TextField
              label="Document name"
              descriptiveText="This is parsed document name."
              value={documentName}
              disabled={true}
          />}
          <Button
            style={{
              marginTop: '1rem',
            }}
            disabled={isUploading}
            onClick={handleSubmit}
          >
            {isUploading ? 'Uploading...' : 'Upload'}
          </Button>
        </View>
      </div>
    </div>
  )
}