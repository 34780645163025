import React, { FC, useCallback } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { GrDocumentUpdate } from 'react-icons/gr'
import { View } from '@aws-amplify/ui-react'

const readFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

const removeDataURLPrefix = (dataURL: string) => {
  return dataURL.split(',')[1]
}

interface DropzoneProps extends DropzoneOptions {
  onFileUpload: (files: File[]) => void;
}

export const Dropzone: FC<DropzoneProps> = ({ onFileUpload, ...props }) => {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 1) {
        alert('Only 1 file allowed')
      }
      const file = acceptedFiles[0]

      const encoded = removeDataURLPrefix(await readFileToBase64(file))

      onFileUpload({ name: file.name, encoded })
    },
    [onFileUpload],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...props,
  })

  return (
    <View
      as={'div'}
      {...getRootProps()}
      style={{
        borderWidth: isDragActive ? '4px' : '2px',
        borderStyle: 'dotted',
        borderColor: isDragActive ? 'blue' : 'gray',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
      }}>
      <input {...getInputProps()} />
      <View
        as={'div'}
      >
        <GrDocumentUpdate size={'3.5rem'} color="#3e87d9"/>
        <p>Drag and drop document here, or click to select files</p>
      </View>
    </View>
  )
}
