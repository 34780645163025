import { Button, Divider, Link, Text, TextAreaField, TextField, View } from '@aws-amplify/ui-react'
import { useEffect, useRef, useState } from 'react'
import { fetcher } from '../../util/fetcher'
import { useLocation } from 'react-router-dom'

export const Search = () => {

  const inputRef = useRef(null)

  const [query, setQuery] = useState()
  const [folderPath, setFolderPath] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [answer, setAnswer] = useState<any | null>(null)
  const [error, setError] = useState<any | null>(null)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const folder = queryParams.get('folder')

  useEffect(() => {
    if (folder) {
      setFolderPath(folder)
    }
  }, [folder])

  const handleQueryRequest = async () => {
    setIsLoading(true)
    setAnswer(null)
    try {
      const data = await fetcher('/docs/query', {
        question: query,
        folder: folderPath ?? undefined,
      })
      if (data) {
        const response = JSON.parse(data.body)
        setIsLoading(false)
        if (response.answer) {
          setAnswer(response.answer)
          setError(null)
        } else {
          setError('Unexpected error')
          throw Error('No answer found')
        }
      }
    } catch (error) {
      console.error(error)
      if (error.message.includes('Forbidden')) {
        alert('Not authorized')
      } else {
        alert('Unexpected error')
      }
      setAnswer(null)
      setIsLoading(false)
    }
  }

  const handleQueryChange = (event) => {
    setQuery(event.target.value)
  }

  return (
    <View
      as="div"
      borderRadius="6px"
      border="1px solid grey"
      color="var(--amplify-colors-blue-60)"
      height="auto"
      padding="1rem"
      width="90vw"
      maxWidth="80rem"
    >
      <h2>Document Search</h2>
      <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }}/>
      <Text style={{ marginBottom: '1rem' }}>Type your question, and AI scans documents to provide accurate responses.</Text>
      <View
        as="div"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: '100%',
          gap: '1rem',
        }}
      >
        <TextAreaField
          ref={inputRef}
          label="Questions"
          placeholder={`Write your question here...`}
          onChange={handleQueryChange}
          style={{
            minWidth: '30rem',
          }}
        />

        <View
          as="div"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '0.5rem',
          }}
        >
          <Text>
            Write folder path to search from. If empty, all documents will be searched. Or open <span><a href="/docs">Document Manager</a></span>
          </Text>
          <TextField
            value={folderPath}
            onChange={(event) => setFolderPath(event.target.value)}
            placeholder={folder ?? '/'}
          />
        </View>
        <Button
          variation="primary"
          disabled={query === ''}
          onClick={handleQueryRequest}
        >
          Ask
        </Button>
      </View>

      <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }}/>
      {isLoading && <Text>Loading...</Text>}
      {answer}
      {error && error}
    </View>
  )
}