import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import '@aws-amplify/ui-react/styles.css'
import { Amplify } from 'aws-amplify'

if (!process.env.REACT_APP_USER_POOL_CLIENT_ID || !process.env.REACT_APP_USER_POOL_ID) {
  throw new Error('No Cognito .env values found')
}

const USER_POOL_CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID

interface GoogleAuthConfig {
  userPoolId: string
  signInUrl: string
  signOutUrl: string
  domain: string
}

const googleAuthConfigMapping: GoogleAuthConfig[] = [
  {
    // DEV
    userPoolId: 'us-west-2_EUM2CZIeK',
    signInUrl: 'http://localhost:3000',
    signOutUrl: 'http://localhost:3000/logout',
    domain: 'devEnv-zeit-energy.auth.us-west-2.amazoncognito.com',
  },
  {
    // STG (not actually used)
    userPoolId: 'us-west-2_bQBSFudHu',
    signInUrl: 'https://employee.zeitenergy.com',
    signOutUrl: 'https://employee.zeitenergy.com/logout',
    domain: 'sandbox-zeit-energy.auth.us-west-2.amazoncognito.com',
  },
  {
    // PROD
    userPoolId: 'us-west-2_kE3XC0xhK',
    signInUrl: 'https://employee.zeitenergy.com',
    signOutUrl: 'https://employee.zeitenergy.com/logout',
    domain: 'production-zeit-energy.auth.us-west-2.amazoncognito.com',
  },
]

/**
 * Get right Google auth config based on used Cognito User Pool ID
 */
const getConfigByUserPoolId = (userPoolId: string): GoogleAuthConfig | undefined => {
  return googleAuthConfigMapping.find(config => config.userPoolId === userPoolId)
}

const googleAuthConfig = getConfigByUserPoolId(USER_POOL_ID)

if (!googleAuthConfig) {
  throw new Error('No googleAuthConfig mapping found')
}

Amplify.configure({
  aws_user_pools_web_client_id: USER_POOL_CLIENT_ID,
  aws_user_pools_id: USER_POOL_ID,
  oauth: {
    domain: googleAuthConfig.domain,
    scope: [
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    responseType: 'code',
    redirectSignIn: googleAuthConfig.signInUrl,
    redirectSignOut: googleAuthConfig.signOutUrl,
  },
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
)
