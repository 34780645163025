import { Auth } from 'aws-amplify'

const API_ENDPOINT_PATH = process.env.REACT_APP_API_ENDPOINT

export const fetcher = async (path: string, body?: any) => {
  if (!API_ENDPOINT_PATH) {
    throw new Error('No API_ENDPOINT_PATH found.')
  }

  const session = await Auth.currentSession()
  const token = session.getAccessToken().getJwtToken()

  const url = `${API_ENDPOINT_PATH}${path}`

  console.log('url', url)

  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: body ? 'POST' : 'GET',
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error(`Fetch error: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error)
    throw error
  }
}