import { Table, TableCell, TableHead, TableRow, View } from '@aws-amplify/ui-react'
import React from 'react'
import { Document } from './Document'
import { Documents } from './Documents'

/**
 * Remove null, undefined, empty strings and 0 values from WO and PO objects
 */
const filterObject = (obj: Record<string, any>): Record<string, any> => {
  const filteredObj: Record<string, any> = {}

  for (const [key, value] of Object.entries(obj)) {
    if (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      value !== 0 &&
      key.indexOf('doc') === -1 &&
      String(value) !== 'null'
    ) {
      filteredObj[key] = value
    }
  }

  return filteredObj
}

interface Data {
  /**
   * Either WO or PO object
   */
  data: Record<string, any>
  /**
   * Related documents
   */
  documents: Document[]
}

interface DataTableProps {
  data: Data
}

export const DataTable = ({ data }: DataTableProps) => {

  const filteredData = filterObject(data.data)

  return (
    <View
      as="div"
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        gap: '1rem',
        flex: 1,
      }}
    >
      <View
        as="div"
        style={{
          flex: 1,
        }}
      >
        <Table
          size={'small'}
          highlightOnHover={true}
        >
          <TableHead>
            <TableRow style={{ textAlign: 'left' }}>
              <TableCell><strong>Key</strong></TableCell>
              <TableCell><strong>Value</strong></TableCell>
            </TableRow>
          </TableHead>
          <tbody>
          {Object.entries(filteredData).map(([key, value]) => (
            <TableRow
              key={key}
              style={{
                cursor: key.includes('docu') ? 'pointer' : 'auto',
              }}
            >
              <TableCell>{key}</TableCell>
              <TableCell>{value === null ? 'null' : value}</TableCell>
            </TableRow>
          ))}
          </tbody>
        </Table>
      </View>
    </View>
  )
}
