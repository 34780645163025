import { Button, CheckboxField, Radio, RadioGroupField, useAuthenticator, View } from '@aws-amplify/ui-react'
import { useEffect, useState } from 'react'
import { fetcher } from '../../util/fetcher'
import { UploadDocumentDropzone } from './UploadDocumentDropzone'
import { OPTIONS } from './EmaintManager'

export enum UPLOAD_MODE {
  VENDOR_INVOICE = 'Vendor Invoice',
  PACKING_SLIP = 'Packing Slip',
  WORK_ORDER_BACKUP = 'Work Order Backup'
}

type UploadModeOptions = {
  name: UPLOAD_MODE,
  printWithWO: boolean
  // Clint special preferences
  clintOnly?: boolean
  // Default option
  preferred?: boolean
}

const uploadModeOptions: UploadModeOptions[] = [
  {
    name: UPLOAD_MODE.VENDOR_INVOICE,
    printWithWO: false,
    clintOnly: true,
  },
  {
    name: UPLOAD_MODE.WORK_ORDER_BACKUP,
    printWithWO: true,
    preferred: true,
  },
  {
    name: UPLOAD_MODE.PACKING_SLIP,
    printWithWO: true,
  },
]

interface ModalProps {
  // WO or PO id
  searchId: string
  // WO or PO
  selectedOption: string
  handleDataUpdate: () => void
  workOrderId?: number
}

type UploadedDocument = {
  name: string
  encoded: string
}

export const UploadDocument = ({ searchId, selectedOption, handleDataUpdate, workOrderId }: ModalProps) => {

  const { user } = useAuthenticator()
  const [selectedUploadMode, setSelectedUploadMode] = useState<string>(uploadModeOptions[1].name)
  const [isUploading, setIsUploading] = useState(false)
  const [attachToWO, setAttachToWO] = useState(true)
  const [printWithWO, setPrintWithWO] = useState(false)

  const [document, setDocument] = useState<UploadedDocument>()

  useEffect(() => {
    const userGroups = user.signInUserSession?.accessToken?.payload?.['cognito:groups'] || []
    const isAdminUser = userGroups.includes('Admin')
    if (isAdminUser) {
      const clintPreference = uploadModeOptions.find((option) => option.clintOnly)
      if (!clintPreference) {
        throw new Error('No Clint preference found.')
      }
      setSelectedUploadMode(clintPreference.name)
      setPrintWithWO(clintPreference.printWithWO)

    } else {
      const defaultPreferred = uploadModeOptions.find((option) => option.preferred)
      if (!defaultPreferred) {
        throw new Error('No Clint preference found.')
      }
      setSelectedUploadMode(defaultPreferred.name)
      setPrintWithWO(defaultPreferred.printWithWO)
    }
  }, [user])

  const handleRadioButtonChange = (event) => {
    const option = uploadModeOptions.find((option) => option.name === event.target.value)
    if (!option) {
      throw new Error('Unexpected option.')
    }
    setSelectedUploadMode(option.name)
    setPrintWithWO(option.printWithWO)
  }

  const constructOptionType = (option: string) => {
    if (option === OPTIONS.WORK_ORDER) {
      return 'wo'
    }
    if (option === OPTIONS.PURCHASE_ODER) {
      return 'po'
    }
  }

  const handleSubmit = async () => {

    if (!document) {
      alert('Choose file first')
      return
    }

    setIsUploading(true)

    await fetcher('/emaint/document/upload', {
      id: searchId,
      option: constructOptionType(selectedOption),
      name: document.name,
      document: document.encoded,
      workOrderId: attachToWO && workOrderId ? workOrderId.toString() : undefined,
      printWithWO,
    }).then(() => {
      setIsUploading(false)
      handleDataUpdate()
    })
      .catch((error) => {
        console.error(error)
        setIsUploading(false)
        alert(`Unexpected error: ${error.message}`)
      })
  }

  const handleFileUpload = (uploadedFile: UploadedDocument) => {
    setDocument(uploadedFile)
  }

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h4>New document:</h4>
      {!document &&
          <div>
              <UploadDocumentDropzone onFileUpload={handleFileUpload} accept=".pdf" multiple={false}/>
          </div>
      }
      {document &&
          <div>
            {!workOrderId && <RadioGroupField
                value={selectedUploadMode}
                onChange={handleRadioButtonChange}
                style={{
                  flexDirection: 'row',
                  marginBottom: '1rem',
                }}
            >
              {uploadModeOptions.map((option) => (
                <Radio key={option.name} value={option.name}>
                  {option.name}
                </Radio>
              ))}
            </RadioGroupField>}
              <div
                  style={{
                    display: 'flex',
                    backgroundColor: '#f0f0f0',
                    padding: '0.5rem',
                    flexDirection: 'row',
                    gap: '2rem',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}>
                  <div>
                      <p style={{ color: 'black' }}>📄 {document.name}</p>
                    {
                      workOrderId &&
                        <CheckboxField
                            size="small"
                            label="Attach document to WO"
                            name="Attach document to WO"
                            checked={attachToWO}
                            onChange={(e) => setAttachToWO(e.target.checked)}
                        />
                    }
                    {
                      !workOrderId &&
                        <CheckboxField
                            size="small"
                            label="Print with WO"
                            name="Print with WO"
                            checked={printWithWO}
                            onChange={(e) => setPrintWithWO(e.target.checked)}
                        />
                    }
                  </div>
                  <Button
                      variation={'primary'}
                      size={'small'}
                      disabled={isUploading}
                      onClick={handleSubmit}
                  >
                    {isUploading ? 'Uploading...' : 'Upload'}
                  </Button>
                  <Button
                      size={'small'}
                      onClick={() => setDocument(null)}
                  >
                    {'Remove'}
                  </Button>
              </div>
          </div>
      }
    </View>
  )
}