import { View } from '@aws-amplify/ui-react'
import DocumentManager from '../../components/DocumentManager/DocumentManager'

export const DocumentManagerRoute = () => {
  return (
    <>
      <View>
        <DocumentManager/>
      </View>
    </>
  )
}