import './App.css';
import { ThemeProvider } from '@aws-amplify/ui-react'
import { RouterProvider } from 'react-router-dom';
import { router } from './pages/router'
import { theme } from './theme'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
