import { HiOutlineDocumentDownload } from 'react-icons/hi'
import { fetcher } from '../../../util/fetcher'
import { useState } from 'react'
import { Loader } from '@aws-amplify/ui-react'

export interface DocumentProps {
  id: string
  title: string
  description?: string

}

export const Document = ({ doc }: DocumentProps) => {

  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadDocument = async () => {
    setIsLoading(true)
    try {
      const response = await fetcher(`/emaint/document/${doc.id}`)

      if (document) {
        const contentType = 'application/pdf'
        const linkSource = `data:${contentType};base64,${response.data}`
        const downloadLink = document.createElement('a')
        downloadLink.href = linkSource
        downloadLink.download = doc.title
        downloadLink.click()
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      if (error instanceof Error) {
        if (error.message.includes('404')) {
          alert('Given Id not found')
        }
      } else {
        alert('Unexpected error')
      }
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '0.25rem',
        }}
      > {isLoading ?
        <div
          style={{
            margin: '0.5rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Loader size='large' style={{ width: '2.5rem', height: '2.5rem'}}/>
        </div> :
        <HiOutlineDocumentDownload
          size={'2.5em'}
          onClick={handleDownloadDocument}
          style={{ cursor: 'pointer' }}
        />}
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <span>{doc.title}</span>
        </div>
      </div>
    </div>
  )
}
