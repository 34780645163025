import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom'
import { CustomAuthenticator } from './auth/Authenticator'
import { Layout } from './layout/Layout';
import { Dashboard } from './dashboard/Dashboard'
import { DocumentManagerRoute } from './docs'
import { SearchRoute } from './search'

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <CustomAuthenticator>
        <Layout>
          <Outlet />
        </Layout>
      </CustomAuthenticator>
    ),
    children: [
      {
        path: '/',
        element: <Dashboard/>
      },
      {
        path: '/docs',
        element: <DocumentManagerRoute/>
      },
      {
        path: '/search',
        element: <SearchRoute/>
      },
    ]
  },
  {
    path: '/logout',
    element: <Navigate to="/" replace />
  }
])