export const theme = {
  name: 'zeitEnergy',
  tokens: {
    colors: {
      brand: {
        primary: {
          100: { value: '#3e87d9' },
        },
        // secondary: {
        //   10: { value: "{colors.yellow.10}" },
        //   20: { value: "{colors.yellow.20}" },
        //   40: { value: "{colors.yellow.40}" },
        //   60: { value: "{colors.yellow.60}" },
        //   80: { value: "{colors.yellow.80}" },
        //   90: { value: "{colors.yellow.90}" },
        //   100: { value: "{colors.yellow.100}" },
        // },
      },
    },
  },
};