
const hasFileExtension = (key: string): boolean => {
  const extensionRegex = /\.[^.]+$/
  return extensionRegex.test(key)
}

export const parseS3Content = (folderPath: string, response) => {

  const rootFolder = {
    name: folderPath,
    objects: [],
    folders: []
  }

  response.Contents.forEach(content => {
    const objectKey = content.Key!
    // console.log('objectKey', objectKey)
    const objectKeyParts = objectKey.split('/')

    if (objectKeyParts.length === 1 && hasFileExtension(objectKey)) {
      // Object is directly under the root folder and has a file extension
      rootFolder.objects.push({
        Key: objectKey,
        fullKey: content.Key!,
      })
    }

    else if (objectKeyParts.length > 1) {
      // Object is inside a sub-folder
      let currentFolder = rootFolder
      for (let i = 0; i < objectKeyParts.length - 1; i++) {
        const folderName = objectKeyParts[i]
        let folder = currentFolder.folders.find(f => f.name === folderName)
        if (!folder) {
          folder = {
            name: folderName,
            objects: [],
            folders: [],
            fullKey: content.Key!,
          }
          currentFolder.folders.push(folder)
        }
        currentFolder = folder
      }
      if (hasFileExtension(objectKeyParts[objectKeyParts.length - 1])) {
        console.log('object.Key', content.Key)

        currentFolder.objects.push({
          Key: objectKeyParts[objectKeyParts.length - 1],
          fullKey: content.Key!,
        })
      }
    }
  })

  return rootFolder
}