import { Authenticator, Heading, View } from '@aws-amplify/ui-react'
import { theme } from '../../theme'

export const CustomAuthenticator = ({ children }: any) => {
  return (
    <Authenticator
      theme={theme}
      hideSignUp={true}
      loginMechanisms={['email']}
      socialProviders={['google']}
      components={{
        Header() {
          return (
            <View textAlign="center" marginTop={'4rem'} paddingBottom={'4rem'}>
              <Heading level={1} fontSize={'2.5rem'}>ZeitEnergy Admin Tools</Heading>
            </View>
          )
        },
      }}
    >
      {children}
    </Authenticator>
  )
}