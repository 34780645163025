import { Button, Divider, Link, View } from '@aws-amplify/ui-react'
import React, { useEffect, useState } from 'react'
import { fetcher } from '../../util/fetcher'
import { FcDocument, FcOpenedFolder } from 'react-icons/fc'
import { MdOutlineUploadFile, MdManageSearch } from 'react-icons/md'
import { UploadDocumentModal } from './UploadDocumentModal'
import { parseS3Content } from './util/parse-s3-content'
import { getFullKey, updateFullKey } from './util/update-full-key'

const DocumentManager = () => {

  const [loading, setLoading] = useState(false)
  const [folderStructure, setFolderStructure] = useState<any | null>(null)
  const [uploadPath, setUploadPath] = useState('')
  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  const listAllDocuments = async () => {
    setLoading(true)
    setFolderStructure()
    // Fetch all documents and sub-folders from the root to populate the view
    const folderPath = 'root'

    try {
      const response = await fetcher(`/docs/${folderPath}`)
      if (response.Contents && response.Contents.length > 0) {
        const rootFolder = parseS3Content(folderPath, response)
        setFolderStructure(rootFolder)
      } else {
        setFolderStructure(null)
      }
    } catch (error) {
      if (error.message.includes('Forbidden')) {
        alert('Not authorized')
      }
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    listAllDocuments()
  }, [])

  const handleModalOpen = async () => setUploadModalOpen(true)
  const handleModalClose = async () => setUploadModalOpen(false)

  const setUploadPathWrapper = async (path) => {
    setUploadPath(path)
  }


  const renderFolderStructure = (folder) => (
    <ul
      style={{
        marginLeft: 0,
        paddingLeft: '1rem',
        listStyleType: 'none',
      }}
    >
      {folder.folders.map(subFolder => (
        <li key={subFolder.name} style={{ listStyle: 'none', marginBottom: '0.5rem' }}>
          <div
            style={{
              display: 'flex',
              gap: 4,
              marginBottom: '0.5rem',
              alignItems: 'center',
            }}
          >
            <FcOpenedFolder size={'1.5rem'} color="#3e87d9"/>
            <strong>{subFolder.name}</strong>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 4,
              }}
            >
              <MdOutlineUploadFile
                size={'2rem'}
                color="#3e87d9"
                onClick={() => setUploadPathWrapper(subFolder).then(() => handleModalOpen(true))}
                style={{
                  cursor: 'pointer',
                }}
              />
              <Link href={`/search?folder=${getFullKey(subFolder)}`} color="white" size="small" variation="warning">
                <MdManageSearch
                  size={'2rem'}
                  color="#3e87d9"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Link>
            </div>
          </div>
          {renderFolderStructure(subFolder)}
        </li>
      ))}
      {folder.objects.map(obj => (
        <li key={obj.Key} style={{ listStyle: 'none', marginBottom: '0.5rem' }}>
          <div
            style={{
              display: 'flex',
              gap: 4,
            }}
          >
            <FcDocument size={'1.5rem'} color="#3e87d9"/>
            {obj.Key}
          </div>

        </li>
      ))}
    </ul>
  )

  return (
    <>
      <View
        as="div"
        borderRadius="6px"
        border="1px solid grey"
        color="var(--amplify-colors-blue-60)"
        height="auto"
        padding="1rem"
        width="90vw"
        maxWidth="80rem"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2>Document Manager</h2>
          <div
            style={{
              display: 'flex',
              gap: 20
            }}
          >
            <Link href={'/search'}>
              <Button
                variation="secondary"
                style={{
                  backgroundColor: '#fff',
                  color: '#3e87d9',
                  border: '1px #3e87d9 solid',
                }}
                onClick={() => handleModalOpen(true)}
              >
                Search
              </Button>
            </Link>
            <Button
              variation="primary"
              onClick={() => handleModalOpen(true)}
            >
              Upload
            </Button>
          </div>
        </div>
        <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }}/>
        <div>
          {loading && <p>Loading documents...</p>}
          {!loading && folderStructure && <div>
            {renderFolderStructure(folderStructure)}
          </div>}
          {!loading && !folderStructure && <p>No document found...</p>}
        </div>
      </View>
      <UploadDocumentModal
        path={uploadPath}
        isOpen={uploadModalOpen}
        onClose={handleModalClose}
        setUploadPath={setUploadPath}
        listAllDocuments={listAllDocuments}
      />
    </>
  )
}

export default DocumentManager