import { View } from '@aws-amplify/ui-react'
import { Search } from '../../components/Search'

export const SearchRoute = () => {
  return (
    <>
      <View>
        <Search/>
      </View>
    </>
  )
}