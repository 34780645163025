import { Button, Divider, Radio, RadioGroupField, SearchField, Text, View } from '@aws-amplify/ui-react'
import React, { useEffect, useRef, useState } from 'react'
import { fetcher } from '../../util/fetcher'
import { DataTable } from './DataTable'
import { Document } from './DataTable/Document'
import { UploadDocument } from './UploadDocument'

export enum OPTIONS {
  PURCHASE_ODER = 'Purchase Order',
  WORK_ORDER = 'Work Order'
}

const options: string[] = [OPTIONS.PURCHASE_ODER, OPTIONS.WORK_ORDER]

const constructFetchPath = (option: string, id: string) => {
  if (option === OPTIONS.WORK_ORDER) {
    return `/emaint/wo/${id}`
  }
  if (option === OPTIONS.PURCHASE_ODER) {
    return `/emaint/po/${id}`
  }
}

export const EmaintManager = () => {

  const inputRef = useRef(null)
  const searchButtonRef = useRef(null)

  const [selectedOption, setSelectedOption] = useState<string>(options[0])
  const [searchId, setSearchId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<any | null>(null)
  const [error, setError] = useState<any | null>(null)

  const handleDataFetch = async (selectedOption, searchId) => {
    setIsLoading(true)
    setData(null)
    try {
      const data = await fetcher(constructFetchPath(selectedOption, searchId))
      setData(data)
      setError(null)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        if (error.message.includes('404')) {
          setError('Given Id not found')
          setData(null)
          setIsLoading(false)
        }
      }
      setError('Unexpected error')
      setData(null)
      setIsLoading(false)
    }
  }

  const handleSearchIdChange = (event) => {
    setSearchId(event.target.value)
  }

  const handleSearchClear = () => {
    setSearchId('')
    setIsLoading(false)
    setData(null)
  }
  const handleRadioButtonChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const [isPO, setIsPO] = useState(false)
  const [workOrderId, setWorkOrderId] = useState(undefined)

  useEffect(() => {
    if (data) {
      const id = Object.entries(data?.data).find(([key, _value]) => key === 'purno')
      if (id) {
        const woId = Object.entries(data?.data).find(([key, _value]) => key === 'wo')[1]
        if (woId === 0) {
          setWorkOrderId(undefined)
        } else {
          setWorkOrderId(woId)
        }
        setIsPO(true)
      } else {
        setIsPO(false)
      }
    }
  }, [data])

  const fetchWO = async () => {
    const id = Object.entries(data?.data).find(([key, _value]) => key === 'wo')
    const newOption = OPTIONS.WORK_ORDER
    handleSearchClear()
    setSelectedOption(newOption)
    setSearchId(id[1].toString())
    await handleDataFetch(newOption, id[1])
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      void handleDataFetch(selectedOption, searchId)
    }
  }

  const handleDataUpdate = async () => {
    await handleDataFetch(selectedOption, searchId)
  }

  return (
    <View
      as="div"
      borderRadius="6px"
      border="1px solid grey"
      color="var(--amplify-colors-blue-60)"
      height="auto"
      padding="1rem"
      width="90vw"
      maxWidth="80rem"
    >
      <h2>Emaint Manager</h2>
      <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }}/>
      <RadioGroupField
        label="Type:"
        value={selectedOption}
        onChange={handleRadioButtonChange}
        style={{
          flexDirection: 'row',
          marginBottom: '1rem',
        }}
      >
        {options.map((option) => (
          <Radio key={option} value={option}>
            {option}
          </Radio>
        ))}
      </RadioGroupField>
      <View
        as="div"
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', flex: 1 }}>
          <SearchField
            ref={inputRef}
            searchButtonRef={searchButtonRef}
            label="Id"
            value={searchId}
            placeholder={`Write ${selectedOption} Id here...`}
            onChange={handleSearchIdChange}
            onClear={handleSearchClear}
            hasSearchButton={false}
            onKeyDown={handleKeyDown}
          />
          <Button
            variation="primary"
            disabled={!searchId || searchId === ''}
            onClick={() => handleDataFetch(selectedOption, searchId)}
          >
            Search
          </Button>
          {
            data && searchId && searchId !== '' && isPO && <Button
                  variation="secondary"
                  size={'small'}
                  onClick={fetchWO}
              >
                  Open related WO
              </Button>
          }
        </div>
      </View>

      <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }}/>

      {isLoading && <Text>Loading...</Text>}
      {!isLoading && error && <div>{JSON.stringify(error)}</div>}

      {data && (
        <View
          as="div"
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            gap: '1rem',
          }}
        >
          <DataTable
            data={data}
            selectedOption={selectedOption} searchId={searchId}
          />
          <View
            as="div"
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              gap: '1rem',
              flex: 1,
            }}
          >
            <View
              as="div"
            >
              {
                data && searchId && searchId !== '' &&
                  <UploadDocument
                      searchId={searchId}
                      selectedOption={selectedOption}
                      handleDataUpdate={handleDataUpdate}
                      workOrderId={workOrderId}
                  />
              }
              <h4>Documents:</h4>
              {data.documents.length > 0 ? data.documents.map((doc: Document) => (
                <div key={doc.id} style={{ marginBottom: '2rem' }}>
                  <Document doc={doc}/>
                </div>
              )) : <p>No any documents found.</p>}
            </View>
          </View>
        </View>
      )}
    </View>
  )

}