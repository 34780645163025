import { EmaintManager } from '../../components/EmaintManager'
import { View } from '@aws-amplify/ui-react'

export const Dashboard = () => {
  return (
    <>
      <View>
        <EmaintManager/>
      </View>
    </>
  )
}