import { Button, Flex, Link, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react'
import { ReactNode } from 'react'

const SignOutButton = () => {
  const { signOut } = useAuthenticator()
  return (
    <Link onClick={signOut}>
      <Button variation="secondary" style={{ backgroundColor: '#000', color: 'white' }}>Sign out</Button>
    </Link>
  )
}

const DocumentMangerButton = () => {
  return (
    <Link href={'/docs'} color="white" size="small" variation="warning">
      <Button variation="primary" style={{ backgroundColor: '#fff', color: '#3e87d9' }}>Document Manager</Button>
    </Link>
  )
}

const DocumentSearchButton = () => {
  return (
    <Link href={'/search'} color="white" size="small" variation="warning">
      <Button variation="primary" style={{ backgroundColor: '#fff', color: '#3e87d9' }}>Document Search</Button>
    </Link>
  )

}

export const Layout = ({ children }: {children: ReactNode}) => {
  const theme = useTheme()

  const { user } = useAuthenticator()

  const userGroups = user.signInUserSession?.accessToken?.payload?.['cognito:groups'] || []

  const isAdminUser = userGroups.includes('Admin')

  return (
    <View>
      <View
        as="header"
        style={{
          width: '100%',
          backgroundColor: theme.tokens.colors.brand.primary[100].value,
        }}
      >
        <Flex padding="20px" alignItems="center" justifyContent="space-between">
          <Link href={'/'}>
            <img src={'./logo-white.png'} className="header-logo" alt="logo" width={'200px'}/>
          </Link>
          <Flex alignItems="center" justifyContent="space-between">
            {isAdminUser && <DocumentSearchButton/>}
            {isAdminUser && <DocumentMangerButton/>}
            <SignOutButton/>
          </Flex>
        </Flex>
      </View>
      <View
        as="div"
        style={{
          display: 'flex',
          marginTop: '2rem',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        {children}
      </View>
    </View>
  )
}
