export const updateFullKey = (obj: any) => {
  const name = obj.name
  const fullKey = obj.fullKey

  const lastSlashIndex = fullKey.lastIndexOf('/')
  const lastPart = fullKey.substring(lastSlashIndex + 1)

  if (name !== lastPart) {
    const newFullKey = fullKey.substring(0, lastSlashIndex)
    obj.fullKey = newFullKey
  }

  return obj
}

export const getFullKey = (obj: any) => {
  const updatedObj = updateFullKey(obj)
  return updatedObj.fullKey
}